import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>WISDOM TEETH</h2>
      <h3>What are they?</h3>
      <p>Wisdom teeth are the last teeth to appear, at the back of the mouth, from the late teens onwards.  Most people have four wisdom teeth, but it is not unusual to have fewer - or even none.</p>
      <p>Because they are the last teeth to form, there sometimes isn't room for them. They come through at an angle, pressing against the teeth in front or the bone behind.</p>
      <h3>What will my dentist do?</h3>
      <p>Watching how your teeth and jaws grow is part of your regular dental care. Do tell your dentist if you think there may be a problem.</p>
      <p>X-ray pictures can show where the wisdom teeth are in the jaw and how much room there is for them to come through,  as well as showing  if they are causing any damage  to the teeth in front.</p>
      <p>The x-rays will also show how simple or difficult it may be to take a wisdom tooth out.</p>

      <p>As wisdom teeth are coming  through, the surrounding gum sometimes becomes inflamed and sore. This is called "pericoronitis". It may settle down or come and go over a period. It is usually better to remove a wisdom tooth if you have repeated bouts of pericoronitis.</p>
      <p>If you need to have a wisdom tooth removed, you should be able to fit it in with work or other commitments.  Sometimes, you may be referred to a specialist to have a problem wisdom tooth removed in hospital.  Most wisdom teeth are removed under a local anaesthetic but depending on how difficult the tooth is to remove, sedation (something to make you drowsy) or general anaesthetic (only given in hospitals) may be considered.</p>
      <p>Depending on your job you may need to take two or three days off work to recover from your wisdom tooth operation. If you have had a general anaesthetic then you will need time off work to recover from the effects of the anaesthetic.</p>
      <p>Sometimes, nerves can be damaged when lower wisdom teeth are removed.  This leads to numbness in part of the tongue or on some teeth and part of the lower lip and chin - but this is usually temporary.</p>
      <p>What are the benefits  of removing wisdom teeth, which are causing  problems?</p>
      <p>If you have your wisdom  teeth removed, there will be no more pain and infection.</p>
      <p>It will usually make it easier for you to keep your mouth clean. Normally there is no need to have wisdom teeth removed unless they are causing you problems.</p>
    </LightboxLayout>
  )
}

export default Page